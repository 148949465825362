import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

class Home extends Component {
  state = {
    posts: [],
    location: "64114",
    searchTerm: "software+developer"
  };
  // Initial call to Indeed API for jobs, returns up to 10 results
  componentDidMount() {
    axios
      .get("https://cors-anywhere.herokuapp.com/api.indeed.com/ads/apisearch", {
        params: {
          publisher: "7778623931867371",
          v: 2,
          format: "json",
          sort: "date",
          q: this.state.searchTerm,
          l: this.state.location,
          highlight: 0
        }
      })
      .then(res => {
        console.log(res.data.results);
        this.setState({
          posts: res.data.results
        });
      });
  }

  render() {
    const { posts } = this.state;
    const postList = posts.length ? (
      posts.map(post => {
        return (
          <div className="card" key={post.jobkey}>
            <img
              className="card-img-top"
              src={
                "//logo.clearbit.com/" +
                post.company
                  .replace(/\s/g, "")
                  .replace(/\,/g, "")
                  .replace(/\./g, "")
                  .replace(/\&/g, "") +
                ".com"
              }
              alt="Company Logo"
              onError={e => {
                e.target.src = "//logo.clearbit.com/clearbit.com";
              }}
            />
            <div className="card-body">
              <h5 className="card-title">{post.jobtitle}</h5>
              <p className="card-text">{post.snippet}</p>
            </div>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Company: {post.company}</li>
              <li className="list-group-item">
                Location: {post.formattedLocation}
              </li>
              <li className="list-group-item">
                Posted: {post.formattedRelativeTime}
              </li>
            </ul>
            <div className="card-body">
              <a href={post.url} className="card-link">
                Apply To This Job
              </a>
              <a href="#" className="card-link">
                Save This Job
              </a>
            </div>
          </div>
        );
      })
    ) : (
      <div className="center">No posts to show</div>
    );

    return (
      <div>
        <div className="container home">
          <h4 className="center">Home</h4>
          {postList}
        </div>
      </div>
    );
  }
}

export default Home;
