import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import SignedInLinks from "./../components/layout/SignedInLinks";
import SignedOutLinks from "./../components/layout/SignedOutLinks";

const Navbar = () => {
  return (
    <nav className="var-wrapper blue darken-4">
      <div className="container">
        <Link to="/" className="left brand-logo offset-1">
          KC Tech Jobs
        </Link>
        <SignedInLinks />
        <SignedOutLinks />
        {/* <ul id="nav-mobile" className="right hide-on-med-and-down">
          <li>
            <NavLink to="/about">About</NavLink>
          </li>
        </ul> */}
      </div>
    </nav>
  );
};
export default Navbar;
