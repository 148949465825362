import React from "react";

const Contact = props => {
  //   setTimeout(() => {
  //     props.history.push("/about");
  //   }, 2000);
  return (
    <div className="container">
      <h4 className="center">Contact</h4>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore,
        sapiente, earum debitis quo natus similique ullam reiciendis
        perspiciatis, asperiores dolorum cupiditate dignissimos exercitationem
        ab ipsa repellat magnam? Aperiam, error quia.
      </p>
    </div>
  );
};

export default Contact;
