import React from "react";
// Functional Component, data will come from props

const ProjectSummary = () => {
  return (
    <div className="card z-depth-0 project-summary">
      <div className="card-content grey-text text-darken-3">
        <span className="card-title">Job Title</span>
        <p>Posted by the Indeed API</p>
        <p className="grey-text">Date</p>
      </div>
    </div>
  );
};
export default ProjectSummary;
