import React from "react";

const ProjectDetails = props => {
  const id = props.match.params.id;
  return (
    <div className="container section project-details">
      <div className="card z-depth-0">
        <div className="card-content">
          <span className="card-title">Project Title - {id}</span>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil
            facere repellendus corporis voluptatem possimus. Quas magni, velit
            dolor voluptatibus magnam odio beatae ullam? Maxime qui doloremque
            vel sed veniam nulla.
          </p>
        </div>
        <div className="card-action grey lighten-4 grey-text">
          <div>Posted by Author</div>
          <div>Date</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
