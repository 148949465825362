import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// Functional Component
const SignedInLinks = () => {
  return (
    <ul className="right">
      <li>
        <NavLink to="/create">New Job</NavLink>
      </li>
      <li>
        <NavLink to="/">Log Out</NavLink>
      </li>
      <li>
        <NavLink to="/" className="btn btn-floating pink lighten-1">
          AM
        </NavLink>
      </li>
    </ul>
  );
};

export default SignedInLinks;
