import React from "react";
// Function Component, as we don't need state

const Notifications = () => {
  return (
    <div>
      <p>Notifications</p>
    </div>
  );
};

export default Notifications;
